import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/home/:oid',
    name: 'home',
    component: Index,
    meta:{
      title: '首页'
    }
  },
  {
    path: '/kechengguangchang',
    name: 'kechengguangchang',
    component: () => import(/* webpackChunkName: "about" */ '../views/kechengguangchang.vue'),
    meta:{
      title: '在线课程'
    }
  },
  {
    path: '/kechengguangchangft/:code?',
    name: 'kechengguangchangft',
    component: () => import(/* webpackChunkName: "about" */ '../views/kechengguangchang_ft.vue'),
    meta:{
      title: '在綫課程'
    }
  },
  {
    path: '/kecheng_detail',
    name: 'kecheng_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/kecheng_detail.vue'),
    meta:{
      title: '课程详情'
    }
  },
  {
    path: '/kechengft_detail',
    name: 'kechengft_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/kechengft_detail.vue'),
    meta:{
      title: '課程詳情'
    }
  },
  {
    path: '/dasaijieshao',
    name: 'dasaijieshao',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dasaijieshao.vue'),
    meta:{
      title: '大赛介绍'
    }
  },
  {
    path: '/zaixianbaoming',
    name: 'zaixianbaoming',
    component: () => import(/* webpackChunkName: "about" */ '../views/Zaixianbaoming.vue'),
    meta:{
      title: '在线报名'
    }
  },
  {
    path: '/wangqisaishi_list',
    name: 'wangqisaishi_list',
    component: () => import(/* webpackChunkName: "about" */ '../views/Wangqisaishi_list.vue'),
    meta:{
      title: '往期赛事'
    }
  },
  {
    path: '/wangqisaishi_detail',
    name: 'wangqisaishi_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Wangqisaishi_detail.vue'),
    meta:{
      title: '往期赛事详情'
    }
  },
  // {
  //   path: '/dasaigonggao',
  //   name: 'dasaigonggao',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dasaigonggao.vue')
  // },
  // {
  //   path: '/ziliaoxiazai',
  //   name: 'ziliaoxiazai',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Ziliaoxiazai.vue')
  // },
  {
    path: '/login/:oid',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta:{
      title: '登录',
      // content: ''
    }
  },
  {
    path: '/js/login/:oid',
    name: 'jslogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/JsLogin.vue'),
    meta:{
      title: '登录'
    }
  },
  {
    path: '/gzzj/login/:oid',
    name: 'gzzjlogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/GzzjLogin.vue'),
    meta:{
      title: '登录'
    }
  },
  {
    path: '/ft/login/:oid',
    name: 'ftlogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/FtLogin.vue'),
    meta:{
      title: '登錄'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue'),
    meta:{
      title: '注册'
    }
  },
  {
    path: '/ft/register',
    name: '/ft/register',
    component: () => import(/* webpackChunkName: "about" */ '../views/FtRegister.vue'),
    meta:{
      title: '注冊'
    }
  },
  {
    path: '/student_competition',
    name: 'student_competition',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_competition.vue'),
    meta:{
      title: '学生中心-我的赛事'
    }
  },
  {
    path: '/student_profile',
    name: 'student_profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_profile.vue'),
    meta:{
      title: '学生中心-个人资料'
    }
  },
  {
    path: '/ft/student_profile',
    name: 'ft_student_profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ft_Student_profile.vue'),
    meta:{
      title: '學生中心-個人資料'
    }
  },
  {
    path: '/student_ongoing',
    name: 'student_ongoing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_ongoing.vue'),
    meta:{
      title: '学生中心-在学课程'
    }
  },
  {
    path: '/certif_apply',
    name: 'certif_apply',
    component: () => import(/* webpackChunkName: "about" */ '../views/Certif_apply.vue'),
    meta:{
      title: '学生中心-证书申请'
    }
  },
  {
    path: '/certif_detail',
    name: 'certif_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Certif_detail.vue'),
    meta:{
      title: '证书详情'
    }
  },
  {
    path: '/student_certif',
    name: 'student_certif',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_certif.vue'),
    meta:{
      title: '学生中心-我的证书'
    }
  },
  {
    path: '/student_credit',
    name: 'student_credit',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_credit.vue'),
    meta:{
      title: '学生中心-我的学分'
    }
  },
  {
    path: '/ft/student_pwd',
    name: 'ft_student_pwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ft_Student_pwd.vue'),
    meta:{
      title: '學生中心-修改密碼'
    }
  },
  {
    path: '/student_pwd',
    name: 'student_pwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/Student_pwd.vue'),
    meta:{
      title: '学生中心-修改密码'
    }
  },
  {
    path: '/baoming',
    name: 'baoming',
    component: () => import(/* webpackChunkName: "about" */ '../views/Baoming.vue'),
    meta:{
      title: '报名'
    }
  },
  {
    path: '/njydbaoming',
    name: 'njydbaoming',
    component: () => import(/* webpackChunkName: "about" */ '../views/Njyd_Baoming.vue'),
    meta:{
      title: '报名'
    }
  },
  {
    path: '/pingshen_index',
    name: 'pingshen_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pingshen_index.vue'),
    meta:{
      title: '评委中心'
    }
  },
  {
    path: '/jspingshen_index',
    name: 'jspingshen_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/JsPingshen_index.vue'),
    meta:{
      title: '评委中心'
    }     
  },
  {
    path: '/gzzjpingshen_index',
    name: 'gzzjpingshen_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/GzzjPingshen_index.vue'),
    meta:{
      title: '评委中心'
    }     
  },
  {
    path: '/pingshen_list',
    name: 'pingshen_list',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pingshen_list.vue'),
    meta:{
      title: '评审项目列表',
      keepAlive: true,
      scrollTop: 0,
    }
  },
  {
    path: '/jspingshen_list',
    name: 'jspingshen_list',
    component: () => import(/* webpackChunkName: "about" */ '../views/JsPingshen_list.vue'),
    meta:{
      title: '评审项目列表'
    }
  },
  {
    path: '/gzzjpingshen_list',
    name: 'gzzjpingshen_list',
    component: () => import(/* webpackChunkName: "about" */ '../views/GzzjPingshen_list.vue'),
    meta:{
      title: '评审项目列表'
    }
  },
  {
    path: '/pingshen_detail',
    name: 'pingshen_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pingshen_detail.vue'),
    meta:{
      title: '项目详情'
    }
  },
  {
    path: '/jspingshen_detail',
    name: 'jspingshen_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/JsPingshen_detail.vue'),
    meta:{
      title: '项目详情'
    }
  },
  {
    path: '/gzzjpingshen_detail',
    name: 'gzzjpingshen_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/GzzjPingshen_detail.vue'),
    meta:{
      title: '项目详情'
    }
  },
  {
    path: '/pingshen_queren',
    name: 'pingshen_queren',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pingshen_queren.vue'),
    meta:{
      title: '评审确认'
    }
  },
  {
    path: '/jspingshen_queren',
    name: 'jspingshen_queren',
    component: () => import(/* webpackChunkName: "about" */ '../views/JsPingshen_queren.vue'),
    meta:{
      title: '评审确认'
    }
  },
  {
    path: '/gzzjpingshen_queren',
    name: 'gzzjpingshen_queren',
    component: () => import(/* webpackChunkName: "about" */ '../views/GzzjPingshen_queren.vue'),
    meta:{
      title: '评审确认'
    }
  },

  /**
   * 职规赛 ---start----
   */
  {
    path: '/stucpan/zglogin/:oid',
    name: 'zglogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgLogin.vue'),
    meta:{
      title: '登录'
    }
  },
  {
    path: '/zgpingshen_index',
    name: 'zgpingshen_index',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgPingshen_index.vue'),
    meta:{
      title: '评委中心'
    }     
  },
  {
    path: '/zgpingshen_list',
    name: 'zgpingshen_list',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgPingshen_list.vue'),
    meta:{
      title: '评审选手列表'
    }
  },
  {
    path: '/zgpingshen_detail',
    name: 'zgpingshen_detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgPingshen_detail.vue'),
    meta:{
      title: '选手详情'
    }
  },
  {
    path: '/zgpingshen_queren',
    name: 'zgpingshen_queren',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgPingshen_queren.vue'),
    meta:{
      title: '承诺书及评审确认单'
    }
  },
  {
    path: '/zgpingshen_video',
    name: 'zgpingshen_video',
    component: () => import(/* webpackChunkName: "about" */ '../views/stucplan/ZgPingshen_video.vue'),
    meta:{
      title: '视频预览'
    }
  },

  /**
   * 职规赛 ---end----
   */



  {
    path: '/touping',
    name: 'touping',
    component: () => import(/* webpackChunkName: "about" */ '../views/Touping.vue')
  },
  {
    path: '/toupingNew',
    name: 'toupingNew',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToupingNew.vue')
  },
  {
    path: '/toupingNewZg',
    name: 'toupingNewZg',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToupingNewZg.vue')
  },
  {
    path: '/toupingNewZgpw',
    name: 'toupingNewZgpw',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToupingNewZgpw.vue')
  },
  {
    path: '/toupingSy',
    name: 'toupingSy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToupingSy.vue')
  },
  {
    path: '/voting',
    name: 'voting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Voting.vue')
  },
  {
    path: '/pwing',
    name: 'pwing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pwing.vue')
  },
  {
    path: '/pwingnew',
    name: 'pwingnew',
    component: () => import(/* webpackChunkName: "about" */ '../views/PwingNew.vue')
  },
  {
    path: '/pwingsp',
    name: 'pwingsp',
    component: () => import(/* webpackChunkName: "about" */ '../views/PwingSp.vue')
  },
  {
    path: '/defenbang',
    name: 'defenbang',
    component: () => import(/* webpackChunkName: "about" */ '../views/Defenbang.vue')
  },
  {
    path: '/defenbangv',
    name: 'defenbangv',
    component: () => import(/* webpackChunkName: "about" */ '../views/Defenbangv.vue')
  },
  {
    path: '/roadshowcj',
    name: 'roadshowcj',
    component: () => import(/* webpackChunkName: "about" */ '../views/Roadshow_cj.vue')
  },
  {
    path: '/Datacenter',
    name: 'Datacenter',
    component: () => import( '../views/Datacenter.vue'),
    meta:{
      title: '现场评审数据中心'
    }
  },
  {
    path: '/DatacenterGJ',
    name: 'DatacenterGJ',
    component: () => import( '../views/DatacenterGJ.vue'),
    meta:{
      title: '实时排行榜'
    }
  },
  {
    path: '/DatacenterToup',
    name: 'DatacenterToup',
    component: () => import( '../views/DatacenterToup.vue'),
    meta:{
      title: '现场评审数据中心'
    }
  },
  {
    path: '/DatacenterNew',
    name: 'DatacenterNew',
    component: () => import( '../views/DatacenterNew.vue'),
    meta:{
      title: '现场评审数据中心'
    }
  },
  {
    path: '/DatacenterNewC',
    name: 'DatacenterNewC',
    component: () => import( '../views/DatacenterNewC.vue'),
    meta:{
      title: '现场评审数据中心'
    }
  },
  {
    path: '/Controlcenter',
    name: 'Controlcenter',
    component: () => import('../views/Controlcenter.vue'),
    meta:{
      title: '专创云场控端'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
    meta:{
      title: '找不到页面啦~'
    }
  }
]
//路由重复
const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  // scrollBehavior(to, from){
  //   setTimeout(()=>{
  //     if(to.name==='pingshen_list'){ //name为路由名字
  //       return {x:0, y: parseInt(localStorage.getItem('scroll'))}//读取本地的scroll
  //     }
  //   },500)
  //   console.log("to",to,"from",from)
  // }
})
export default router
